.db-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 15vh;
    width: 100vw;
    overflow-y: scroll;
    margin-bottom: 120px;
}

/* creo il css per questo codice             
<div className='db-label-tabel'>
    <div className='db-label'>Database</div>
    <div className='db-tabel'>
        <div className='db-tabel-label'>Label</div>
        <div className='db-tabel-label'>Label</div>
        <div className='db-tabel-label'>Label</div>
        <div className='db-tabel-label'>Label</div>
        <div className='db-tabel-label'>Label</div>
        <div className='db-tabel-label'>Label</div>
    </div>
</div> */

.db-label-tabel {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 90vw;
    border: 1px solid black;
}

.db-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: 1px solid black;
}

.db-tabel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    border: 1px solid black;
}

.db-tabel-label {
    border-right-style: solid;
    border-color: black;
    border-width: 1px;
    padding: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: pointer;
}

.styled-table  {
    table-layout: fixed;
    border-collapse: collapse;
    width: 90vw;
    max-width: 90vw;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
  }  
.styled-table th,
.styled-table td {
    padding: 25px;
    overflow: hidden;
    white-space: nowrap;
    width: 100px;
}

.styled-table thead tr {
    background-color: #009879;
    color: #ffffff;
    text-align: left;
}

/*.styled-table th,
.styled-table td {
    padding: 12px 15px;
}*/

.styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
}

.styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
}

.styled-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
}

.database-btn-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
    justify-content: flex-start;
    width: 91vw;
    margin-top: 20px;
    gap: 23px;
}

.database-btn-container > a {
    --antd-wave-shadow-color: #00d58b;
    --scroll-bar: 0;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    --blue: #556ee6;
    --indigo: #564ab1;
    --purple: #6f42c1;
    --red: #f46a6a;
    --orange: #f1734f;
    --yellow: #f1b44c;
    --green: #34c38f;
    --teal: #050505;
    --cyan: #50a5f1;
    --white: #fff;
    --gray: #74788d;
    --gray-dark: #343a40;
    --primary: #556ee6;
    --secondary: #74788d;
    --success: #34c38f;
    --info: #50a5f1;
    --warning: #f1b44c;
    --danger: #f46a6a;
    --light: #eff2f7;
    --dark: #343a40;
    --pink: #e83e8c;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Poppins", sans-serif;
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    box-sizing: border-box;
    touch-action: manipulation;
    background-color: #17ec04;
    color: white;
    padding: 8px 16px;
    border: none;
    outline: none !important;
    border-radius: 0;
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    cursor: pointer;
    
}



/* Stile per la modal */
.modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .modal-database {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 300px;
  }
  
  .modal-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .modal-btn-container {
    display: flex;
    justify-content: flex-end;
  }
  
  .btn-delete {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    margin-left: 10px;
    cursor: pointer;
  }

  .btn-delete-no {
    background-color: #a8f436;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 8px 12px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .btn-delete:hover {
    background-color: #d32f2f;
  }

.blueviolet{
    color: #331dda;
}