.add-tickets-container {
    display:flex;
    width: 100vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15vh;
    margin-bottom: 120px;
}


.container-select {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 90vw;
    margin-bottom: 20px;
}

.first-select {
    flex-basis: 40%;
}

.second-select {
    flex-basis: 40%;
}
.container-title {
    --antd-wave-shadow-color: #1890ff;
    --scroll-bar: 0;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    --blue: #556ee6;
    --indigo: #564ab1;
    --purple: #6f42c1;
    --red: #f46a6a;
    --orange: #f1734f;
    --yellow: #f1b44c;
    --green: #34c38f;
    --teal: #050505;
    --cyan: #50a5f1;
    --white: #fff;
    --gray: #74788d;
    --gray-dark: #343a40;
    --primary: #556ee6;
    --secondary: #74788d;
    --success: #34c38f;
    --info: #50a5f1;
    --warning: #f1b44c;
    --danger: #f46a6a;
    --light: #eff2f7;
    --dark: #343a40;
    --pink: #e83e8c;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Poppins", sans-serif;
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-variant: tabular-nums;
    font-feature-settings: 'tnum', "tnum";
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: left;
    box-sizing: border-box;
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    padding-bottom: 24px;
    width: 100vw;
}
.left-title {
    font-size: 16px;
    color: #74788d;
    padding:5px;
}

.right-title {
    font-size: 0.8125rem;
    color: #74788d;
    padding:5px;
}