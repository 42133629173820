.style-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    gap: 20px;
    width: 100vw;
    margin-top: 40px;
    flex-wrap: wrap;
}

.upload-file {
    display: none; /* Nasconde l'input di default */
  }
  
  /* Stile personalizzato per il pulsante */
  button {
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 16px;
  }
  
  button:hover {
    background-color: #0056b3;
  }

  .title_upload {
    flex-basis: 24%;
  }
  .title_upload_php{
    align-self: flex-start;
    margin-left: 11.4vw;
  }

  .btn-container-upload {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-basis: 40%;
  
  }

  .btn-container-upload button {
    margin-right: 5px;
  }

  .file-name-upload {
    flex-basis: 20%;
    font-family: Arial;
    margin: 5px 0;
    padding: 1%;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 100%;
    color: #252525;
  }

  .container_mail {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .editor_email_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    width: 100vw;
  }

  .row_editor_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    width: 100vw;
    margin-top: 20px;
  }

  .file_column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 30%;
  }

  .file_tag {
    flex-basis: 40%;
  }
  .file_view {
    flex-basis: 40%;
  }


  .editor_subject_email { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-basis: 40%;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .editor_subject_email h3 {
    margin-bottom: 10px;
  }


.delimiter {
  margin-top: 8px;
  width: 100%;
  height: 2px;
  background-color: #000;
  margin-bottom: 8px;
}